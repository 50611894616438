exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-unsubscription-js": () => import("./../../../src/pages/unsubscription.js" /* webpackChunkName: "component---src-pages-unsubscription-js" */),
  "component---src-templates-candidate-js": () => import("./../../../src/templates/candidate.js" /* webpackChunkName: "component---src-templates-candidate-js" */),
  "component---src-templates-congress-congress-inner-js": () => import("./../../../src/templates/congress/congress-inner.js" /* webpackChunkName: "component---src-templates-congress-congress-inner-js" */),
  "component---src-templates-congress-congress-js": () => import("./../../../src/templates/congress/congress.js" /* webpackChunkName: "component---src-templates-congress-congress-js" */),
  "component---src-templates-event-event-js": () => import("./../../../src/templates/event/event.js" /* webpackChunkName: "component---src-templates-event-event-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-list-candidates-js": () => import("./../../../src/templates/list-candidates.js" /* webpackChunkName: "component---src-templates-list-candidates-js" */),
  "component---src-templates-list-events-js": () => import("./../../../src/templates/list-events.js" /* webpackChunkName: "component---src-templates-list-events-js" */),
  "component---src-templates-list-jobs-js": () => import("./../../../src/templates/list-jobs.js" /* webpackChunkName: "component---src-templates-list-jobs-js" */),
  "component---src-templates-list-members-js": () => import("./../../../src/templates/list-members.js" /* webpackChunkName: "component---src-templates-list-members-js" */),
  "component---src-templates-list-news-js": () => import("./../../../src/templates/list-news.js" /* webpackChunkName: "component---src-templates-list-news-js" */),
  "component---src-templates-list-podcast-js": () => import("./../../../src/templates/list-podcast.js" /* webpackChunkName: "component---src-templates-list-podcast-js" */),
  "component---src-templates-list-policy-paper-list-policy-papers-js": () => import("./../../../src/templates/list-policy-paper/list-policy-papers.js" /* webpackChunkName: "component---src-templates-list-policy-paper-list-policy-papers-js" */),
  "component---src-templates-list-positions-js": () => import("./../../../src/templates/list-positions.js" /* webpackChunkName: "component---src-templates-list-positions-js" */),
  "component---src-templates-list-press-releases-js": () => import("./../../../src/templates/list-press-releases.js" /* webpackChunkName: "component---src-templates-list-press-releases-js" */),
  "component---src-templates-manifesto-js": () => import("./../../../src/templates/manifesto.js" /* webpackChunkName: "component---src-templates-manifesto-js" */),
  "component---src-templates-member-member-js": () => import("./../../../src/templates/member/member.js" /* webpackChunkName: "component---src-templates-member-member-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-position-position-js": () => import("./../../../src/templates/position/position.js" /* webpackChunkName: "component---src-templates-position-position-js" */),
  "component---src-templates-post-podcast-js": () => import("./../../../src/templates/post/podcast.js" /* webpackChunkName: "component---src-templates-post-podcast-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-resolution-resolution-js": () => import("./../../../src/templates/resolution/resolution.js" /* webpackChunkName: "component---src-templates-resolution-resolution-js" */)
}

