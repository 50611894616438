module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7d456261f8384ef1934a4d95f04c4f5f@o4505345599012864.ingest.sentry.io/4505368296095744","tracesSampleRate":1,"environment":"development","enabled":true,"replaysSessionSampleRate":0.1,"replaysOnErrorSampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
